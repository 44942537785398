
import React from 'react';
export const MAINFILE = React.lazy(() => import('../components/Main'));
export const EMAIL_LOGIN = React.lazy(() => import('../components/Login/EmailLogin'));
export const MOBILE_LOGIN = React.lazy(() => import('../components/Login/MobileLogin'));
export const PROCESS = React.lazy(() => import('../components/FormProcess/ProcessScreen'));
export const AADHAAR_KYC = React.lazy(() => import('../components/FormProcess/aadhar'));
export const PAN_KYC = React.lazy(() => import('../components/FormProcess/Pan'));
export const ELIGIBILITY_FORM = React.lazy(() => import('../components/FormProcess/eligibilityform'));
export const BANK_FORM = React.lazy(() => import('../components/FormProcess/bankDetails'));
export const MORE_INFO = React.lazy(() => import('../components/FormProcess/moreinfo'));
export const LOAN_OFFERS = React.lazy(() => import('../components/FormProcess/loanOffers'));
export const UPLOAD_DOCUMENTS = React.lazy(() => import('../components/FormProcess/UploadDocuments'));
export const LOAN_APPLICATION = React.lazy(() => import('../components/FormProcess/LoanApplication'));
export const DASHBOARD_MAIN = React.lazy(() => import('../components/DashboardComponent/Main'));

