// store.js

import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from './Reducers';
import loggerMiddleware from './MiddleWare';

// Configuration for redux-persist
const persistConfig = {
  key: 'root',
  storage,
  // You can whitelist or blacklist specific reducers here
  // whitelist: ['user'], // only persist the 'user' reducer
  // blacklist: ['someOtherReducer'], // exclude specific reducers
};

// Wrap the root reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(loggerMiddleware),
});

// Create the persistor for the store
export const persistor = persistStore(store);
