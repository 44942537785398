import { display } from '@mui/system';
import { combineReducers } from 'redux';
import initialState from './InitialState';

const SaveUserDataReducer = (state = initialState.LoginUserData, action) => {
  switch (action.type) {
    case 'SAVEDATA':
      return {  ...state,value:action.payload};
    default:
      return state;
  }
};

const SaveUserLoanDataReducer = (state = initialState.LoanDetailData, action) => {
  switch (action.type) {
    case 'SAVELOANDATA':
      return {  ...state,value:action.payload};
    default:
      return state;
  }
};


const rootReducer = combineReducers({
  UserData: SaveUserDataReducer,
  UserLoanData: SaveUserLoanDataReducer,
});

export default rootReducer;
