export default {
    EMAIL_LOGIN: '/emaillogin',
    MOBILE_LOGIN: '/mobilelogin',
    
    PROCESS:'/process/processScreen',
    AADHAAR_KYC:'/process/aadhaarkyc',
    PAN_KYC:'/process/pankyc',
    ELIGIBILITY_FORM:'/process/eligibility',
    BANK_FORM:'/process/bankdetails',
    MORE_INFO:'/process/moreinfo',
    LOAN_OFFERS:'/process/loanoffers',
    UPLOAD_DOCUMENTS:'/process/uploaddocument',
    LOAN_APPLICATION:'/process/loanapplication',

    DASHBOARD_MAIN:'/dashboard',





}