import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from '../Redux/Index'
import * as Routers from './router';
import RoutesUrl from './routes';
import { CircularLoader, Loader2 } from '../components/CustomComponent/Loaders';
import { PersistGate } from 'redux-persist/integration/react';
const AppRouter = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Suspense fallback={<Loader2 />}>
          {/* <Suspense fallback={<CircularLoader backgroundType={'transparent'} />}> */}
          <Routes>
            <Route path="/" element={<Routers.MAINFILE />}>
              <Route path={RoutesUrl.EMAIL_LOGIN} element={<Routers.EMAIL_LOGIN />} />
              <Route path={RoutesUrl.MOBILE_LOGIN} element={<Routers.MOBILE_LOGIN />} />
              <Route path={RoutesUrl.PROCESS} element={<Routers.PROCESS />} />
              <Route path={RoutesUrl.AADHAAR_KYC} element={<Routers.AADHAAR_KYC />} />
              <Route path={RoutesUrl.PAN_KYC} element={<Routers.PAN_KYC />} />
              <Route path={RoutesUrl.ELIGIBILITY_FORM} element={<Routers.ELIGIBILITY_FORM />} />
              <Route path={RoutesUrl.BANK_FORM} element={<Routers.BANK_FORM />} />
              <Route path={RoutesUrl.MORE_INFO} element={<Routers.MORE_INFO />} />
              <Route path={RoutesUrl.LOAN_OFFERS} element={<Routers.LOAN_OFFERS />} />
              <Route path={RoutesUrl.UPLOAD_DOCUMENTS} element={<Routers.UPLOAD_DOCUMENTS />} />
              <Route path={RoutesUrl.LOAN_APPLICATION} element={<Routers.LOAN_APPLICATION />} />
              <Route path={RoutesUrl.DASHBOARD_MAIN} element={<Routers.DASHBOARD_MAIN />} />
            </Route>
          </Routes>
        </Suspense>
      </Router>
    </PersistGate>
  </Provider>
);

export default AppRouter;
